import Vue from 'vue'
import App from './App.vue'
import titleMixin from "@/mixins/titleMixin";
import vuetify from './plugins/vuetify';
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";

Vue.use(LottieVuePlayer);
Vue.mixin(titleMixin);

Vue.config.productionTip = false

new Vue({
    vuetify,
    render: h => h(App)
}).$mount('#app')


