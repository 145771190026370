

export const REQUEST_METHODS = {
    POST: "post",
    GET: "get",
    PUT: "put",
    DELETE: "delete",
    UPLOAD_FILE: "upload_file",
    DOWNLOAD_FILE: "download_file",
    DOWNLOAD_FILE_WITH_BODY: "download_file_with_ody"
};

class RequestMethods {}

export default new RequestMethods();
