<template>
  <v-app class="app">
    <div
        style="height: 50px; width: 100%; background-color: #1d6fb7; padding-top: 5px; position: fixed; top:0; z-index: 1200"
        class="text-center"
    >
      <v-img src="@/assets/monopoly.png" height="40px" width="40px" style="margin: auto"></v-img>
    </div>

    <v-row class="two-col-row">
      <v-col cols="12" sm="12" md="12">
        <v-card class="card mb-1 rounded-xl" >
          <v-card-title>
            Statistika
          </v-card-title>
          <p class="ml-5"><v-icon color="white">mdi-arrow-down</v-icon>Igre v tekočem letu</p>
          <v-sparkline
              title="Igre v tekočem letu"
              :value="statistics.gamesPerMonth"
              :gradient="['#f72047', '#ffd200', '#1feaea']"
              :smooth="10"
              :padding="8"
              :line-width="2"
              :stroke-linecap="'round'"
              :gradient-direction="'top'"
              :fill="true"
              :auto-line-width="false"
              auto-draw
              style="width: 100%;"
          >
            <template v-slot:label="{ index }">
              {{ index + 1 }}
            </template>
          </v-sparkline>

          <div class="stat-card-container">
            <div v-for="(playerStats, playerName) in statistics.playerStatistics" :key="playerName" class="stat-card">
              <h4 class="mb-2">{{playerName}}</h4>
              <div class="chip zelen">
                <div>Zmage </div>
                <div style="flex-grow: 100;" class="text-center"><v-icon class="mx-5" color="white">mdi-arrow-right</v-icon></div>
                <div>{{playerStats.wins}}x ({{playerStats.winPercentage}}%)</div>
              </div>
              <div class="chip siv">
                <div>Skupni cash</div>
                <div style="flex-grow: 100;" class="text-center"><v-icon class="mx-6" color="white">mdi-arrow-right</v-icon></div>
                <div>{{playerStats.allCash}} M</div>
              </div>
              <div class="chip rdec">
                <div>Kapitulacije </div>
                <div style="flex-grow: 100;" class="text-center"><v-icon class="mx-5" color="white">mdi-arrow-right</v-icon></div>
                <div>{{playerStats.capitulations}}x</div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="two-col-row">
      <v-col cols="12" sm="12" md="6">
        <v-card class="card mb-1 rounded-xl" >
          <v-card-title>
            Rekordi (TOP 3)
          </v-card-title>
          <v-data-table
              :headers="[
                      { text: 'Datum', align: 'start', sortable: true, value: 'date' },
                      { text: 'Zamgal', align: 'start', sortable: false, value: 'winner' },
                      { text: 'Cash', align: 'start', sortable: false, value: 'winnerCash' },
                      { text: 'Vsa sredstva', align: 'start', sortable: true, value: 'allCash' },
                  ]"
              :items="leaderboard.records"
              :loading="loading"
              loading-text="Nalagam"
              no-data-text="Ni rekordov"
              no-results-text="Ni rekordov"

              :header-props="{
                     'sort-by-text-text': 'Sortiraj po'
                  }"
              :footer-props="{
                     'items-per-page-text': 'rekordov na stran' + ':'
                  }"
              class="table"

              item-key="winnerCash"
              show-expand
              :single-expand="true"
              mobile-breakpoint="100px"
              hide-default-footer
          >
            <template v-slot:item.date = item>
              <b>{{ formatDate(item.item.date) }}</b>
            </template>

            <template v-slot:item.allCash = item>
              <b>{{ calculateGameCash(item.item) }}</b>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="padding: 10px">
                <div style="padding: 10px">
                  <v-chip
                      v-for="property in item.properties" :key="property"
                      class="ml-1 mt-1"
                      :color="property.color"
                  >
                    <span class="mr-1" style="color: white">{{ property.name }}</span>
                    <v-icon v-for="house in property.houses" :key="house.id" small color="green">mdi-home</v-icon>
                    <v-icon v-for="hotel in property.hotels" :key="hotel.id" small color="red">mdi-home</v-icon>
                  </v-chip>
                </div>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-card class="card rounded-xl">
          <v-card-title>
            Igre
            <v-spacer></v-spacer>
            <v-btn
                x-small
                fab
                color="success"
                style="float: right"
                @click="newGameDialog = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
              :headers="[
                      { text: 'Datum', align: 'start', sortable: true, value: 'date' },
                      { text: 'Zamgal', align: 'start', sortable: false, value: 'winner' },
                      { text: 'Cash', align: 'start', sortable: true, value: 'winnerCash' },
                      { text: 'Vsa sredstva', align: 'start', sortable: true, value: 'allCash' },
                  ]"
              :items="leaderboard.games"
              :loading="loading"
              loading-text="Nalagam"
              no-data-text="Ni iger"
              no-results-text="Ni iger"
              :header-props="{
                     'sort-by-text-text': 'Sortiraj po'
                  }"
              :footer-props="{
                     'items-per-page-text': 'Iger na stran' + ':'
                  }"
              class="table"

              item-key="winnerCash"
              show-expand
              :single-expand="true"
              mobile-breakpoint="100px"
          >
            <template v-slot:item.date = item>
              <b>{{ formatDate(item.item.date) }}</b>
            </template>

            <template v-slot:item.allCash = item>
              <b>{{ calculateGameCash(item.item) }}</b>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="padding: 10px">
                <div style="padding: 10px">
                  <v-chip
                      v-for="property in item.properties" :key="property"
                      class="ml-1 mt-1"
                      :color="property.color"
                  >
                    <span class="mr-1" style="color: white">{{ property.name }}</span>
                    <v-icon v-for="house in property.houses" :key="house.id" small color="green">mdi-home</v-icon>
                    <v-icon v-for="hotel in property.hotels" :key="hotel.id" small color="red">mdi-home</v-icon>
                  </v-chip>
                </div>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>


    <v-row class="two-col-row">
      <v-col cols="12" sm="12" md="12">
        <v-card class="card mb-1 rounded-xl" >
          <v-card-title>
            Predaje in Sramota
            <v-spacer></v-spacer>
            <v-btn
                x-small
                fab
                color="error"
                style="float: right"
                @click="newCapitulationDialog = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
              :headers="[
                      { text: 'Datum', align: 'start', sortable: true, value: 'date' },
                      { text: 'Kapitulator', align: 'start', sortable: false, value: 'capitulatee' },
                      { text: 'Zmagovalci', align: 'start', sortable: true, value: 'winners' },
                  ]"
              :items="leaderboard.capitulations"
              :loading="loading"
              loading-text="Nalagam"
              no-data-text="Ni kapitulacij"
              no-results-text="Ni kapitulacij"

              :header-props="{
                     'sort-by-text-text': 'Sortiraj po'
                  }"
              :footer-props="{
                     'items-per-page-text': 'kapitulacij na stran' + ':'
                  }"
              class="table"
          >
            <template v-slot:item.date = item>
              <b>{{ formatDate(item.item.date) }}</b>
            </template>

            <template v-slot:item.capitulatee = item>
              <b style="color: white; font-size: 16px">{{ item.item.capitulatee }}</b>
            </template>

            <template v-slot:item.winners = item>
              <v-expansion-panels style="padding-bottom: 15px">
                <v-expansion-panel
                    v-for="winner in item.item.winners"
                    :key="winner.name"
                    style="
                      background: rgba(255, 255, 255, 0.19);
                      border-radius: 16px;
                      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                      backdrop-filter: blur(5px);
                      -webkit-backdrop-filter: blur(5px);
                      margin: 15px 0 0 0"
                >
                  <v-expansion-panel-header>
                    <div style="color: white">
                      <h3>{{winner.name}}</h3>
                      <p class="mb-0" style="font-size: 13px">Likvidna sredstva: {{winner.cash}}</p>
                      <p class="mb-0" style="font-size: 13px">Vsa sredstva: {{ calculateCapitulationCash(winner) }}</p>
                    </div>

                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-chip
                        v-for="property in winner.properties" :key="property"
                        class="ml-1 mt-1"
                        :color="property.color"
                    >
                      <span class="mr-1" style="color: white">{{ property.name }}</span>
                      <v-icon v-for="house in property.houses" :key="house.id" small color="green">mdi-home</v-icon>
                      <v-icon v-for="hotel in property.hotels" :key="hotel.id" small color="red">mdi-home</v-icon>
                    </v-chip>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>




    <div style="width: 100%" class="text-center mt-6">
      <p class="muted">Developed by BIT-TECH</p>
      <p class="muted">© BIT-TECH, 2024</p>
    </div>


    <yubikey-prompt ref="yubi-prompt"></yubikey-prompt>



    <v-dialog
        v-model="newGameDialog"
        max-width="600"
        persistent
    >
      <v-card style="padding: 10px" :loading="loading">
        <v-card-title class="text-h5">
          <span>Dodaj igro</span>
        </v-card-title>
        <v-card-text class="mt-6 mb-3" :style="$vuetify.breakpoint.mdAndDown ? 'padding: 0' : ''">
          <v-alert
              dense
              type="info"
              style="font-size: 14px"
          >
            Izpolni vse zahtevane podatke o igri in zmagovalcu. Rekordi se naložijo samodejno glede na vnešene igre
          </v-alert>


          <v-card class="card-inner" outlined>
            <h3 class="mb-2">Splošno</h3>
            <hr style="border-top: 1px solid var(&#45;&#45;v-gray2-base)" class="mb-2">
            <v-menu
                ref="menu"
                v-model="newGame.dateMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="computedNewGameDate"
                    label='Datum igre'
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    style="height: 45px"
                    class="mt-4 mb-4"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="newGame.date"
                  locale="sl"
                  no-title
                  scrollable
              ></v-date-picker>
            </v-menu>
            <hr style="border-top: 1px solid var(&#45;&#45;v-gray2-base)" class="mb-2">
            <h4 class="mb-5">
              Igralci:
              <v-btn
                  x-small
                  fab
                  color="success"
                  style="float: right"
                  @click="newGame.players.push('')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </h4>
            <div v-for="(player, index) in newGame.players" :key="player.id">
              <v-text-field
                  v-model="newGame.players[index]"
                  outlined
                  dense
                  append-outer-icon="mdi-trash-can"
                  @click:append-outer="deletePlayer(player)"
                  style="height: 45px"
              ></v-text-field>
            </div>
            <p class="muted mb-0">*Vedno uporabljaj enako ime igralca zaradi pravilnega vodenja statistike!</p>
          </v-card>

          <v-card class="card-inner mt-5" outlined>
            <h3 class="mb-2">Podatki o zmagi</h3>
            <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
            <h4 class="mb-5">
              Zmagovalec:
            </h4>
            <v-radio-group v-model="newGame.winner">
              <v-radio
                  v-for="player in newGame.players"
                  :key="player.id"
                  :label="`${player}`"
                  :value="player"
              ></v-radio>
            </v-radio-group>
            <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
            <h4 class="mb-1">Likvidna sredstva:</h4>
            <v-text-field
                v-model="newGame.winnerCash"
                outlined
                dense
                style="height: 45px"
                type="number"
                label="Monopoly money"
                class="my-4"
            ></v-text-field>
            <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
            <h4 class="mb-2">Zemljišča:</h4>
            <v-select
                v-model="newGame.properties"
                :items="availableProperties"
                outlined
                chips
                return-object
                item-text="name"
                label="Dodaj zemljišča zmagovalca"
                multiple
            ></v-select>

            <div style="width: 100%; overflow-x: auto; white-space: nowrap;">
              <v-card
                  v-for="property in newGame.properties" :key="property"
                  style="height: 350px; width: 250px!important; display: inline-block; border: solid 1px black"
                  class="ml-2"
                  outlined
              >
                <div v-if="property.type === 'property'">
                  <div
                      :style="'background-color: ' + property.color"
                      class="text-center pb-1 pt-3"
                  >
                    <b style="font-size: 10px; color: white">LASTNIŠKA LISTINA</b>
                    <p style="font-size: 19px; font-weight: bold; color: white">{{ property.name }}</p>
                  </div>
                  <div style="display: flex; width: 100px; white-space: nowrap; padding: 10px">
                    <b style="margin-top: 4px; margin-right: 50px">Število hiš: </b>
                    <v-btn
                        x-small
                        fab
                        color="secondary"
                        :disabled="property.hotels !== 0"
                        @click="incrementHouse(property, -1)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <b style="font-size: 25px; margin-top: 5px" class="mx-3">{{ property.houses }}</b>
                    <v-btn
                        x-small
                        fab
                        color="primary"
                        :disabled="property.hotels !== 0"
                        @click="incrementHouse(property, 1)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div style="display: flex; width: 100px; white-space: nowrap; padding: 10px">
                    <b style="margin-top: 4px; margin-right: 22px">Število hotelov: </b>
                    <v-btn
                        x-small
                        fab
                        color="secondary"
                        @click="incrementHotel(property, -1)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <b style="font-size: 25px; margin-top: 5px" class="mx-3">{{ property.hotels }}</b>
                    <v-btn
                        x-small
                        fab
                        color="primary"
                        @click="incrementHotel(property, 1)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
                  <div style="padding: 10px; margin-top: 20px">
                    <b>Cena vsake hiše: {{ property.housePrice }}</b>
                    <p style="font-weight: bold; margin-bottom: 0">Cena zemljišča: {{ property.price }}</p>
                  </div>
                  <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
                  <div style="padding: 10px; margin-top: 10px">
                    <b>Skupni vložek: {{ (property.housePrice * property.houses) + (property.housePrice * 5 * property.hotels) + property.price}}</b>
                    <div class="text-center">
                      <i style="font-size: 10px">© 1935, 2018 HASBRO</i>
                    </div>
                  </div>
                </div>
                <div v-else-if="property.type === 'railway'">
                  <div
                      class="text-center pb-1 pt-3"
                  >
                    <v-img src="@/assets/train.png" style="width: 120px; margin: auto"></v-img>
                    <p style="font-size: 19px; font-weight: bold; margin-top: 20px">{{ property.name }}</p>
                  </div>
                  <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
                  <hr style="border-top: 1px solid var(--v-gray2-base); margin-top: 63px" class="mb-2">
                  <div style="padding: 10px; margin-top: 5px">
                    <p style="font-weight: bold; margin-bottom: 0">Cena železnice: {{ property.price }}</p>
                    <div class="text-center">
                      <i style="font-size: 10px">© 1935, 2018 HASBRO</i>
                    </div>
                  </div>
                </div>
                <div v-else-if="property.type === 'utility'">
                  <div
                      class="text-center pb-1 pt-3"
                  >
                    <v-img v-if="property.name.includes('RAZSVETLJAVA')" src="@/assets/bulb.webp" style="width: 120px; margin: auto"></v-img>
                    <v-img v-else src="@/assets/water.webp" style="width: 120px; margin: auto"></v-img>
                    <p style="font-size: 19px; font-weight: bold; margin-top: 20px">{{ property.name }}</p>
                  </div>
                  <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
                  <hr style="border-top: 1px solid var(--v-gray2-base); margin-top: 74px" class="mb-2">
                  <div style="padding: 10px; margin-top: 5px">
                    <p style="font-weight: bold; margin-bottom: 0">Cena javne storitve: {{ property.price }}</p>
                    <div class="text-center">
                      <i style="font-size: 10px">© 1935, 2018 HASBRO</i>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
          </v-card>

          <v-alert
              dense
              type="error"
              style="font-size: 14px; margin-top: 25px"
              v-if="errorMessage"
          >
            {{errorMessage}}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="secondary"
              outlined
              small
              @click="newGameDialog = false;"
          >
            Prekliči
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="success"
              small
              @click="saveGame()"
              :loading = loading
          >
            Shrani
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="newCapitulationDialog"
        max-width="600"
        persistent
    >
      <v-card style="padding: 10px" :loading="loading">
        <v-card-title class="text-h5">
          <span>Dodaj kapitulacijo</span>
        </v-card-title>
        <v-card-text class="mt-6 mb-3" :style="$vuetify.breakpoint.mdAndDown ? 'padding: 0' : ''">

          <v-card class="card-inner" outlined>
            <h3 class="mb-2">Splošno</h3>
            <hr style="border-top: 1px solid var(&#45;&#45;v-gray2-base)" class="mb-2">
            <v-menu
                ref="menu"
                v-model="newCapitulation.dateMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="computednewCapitulationDate"
                    label='Datum igre'
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    style="height: 45px"
                    class="mt-4 mb-4"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="newCapitulation.date"
                  locale="sl"
                  no-title
                  scrollable
              ></v-date-picker>
            </v-menu>
            <hr style="border-top: 1px solid var(&#45;&#45;v-gray2-base)" class="mb-2">
            <h4 class="mb-5">
              Igralci:
              <v-btn
                  x-small
                  fab
                  color="success"
                  style="float: right"
                  @click="newCapitulation.players.push('')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </h4>
            <div v-for="(player, index) in newCapitulation.players" :key="player.id">
              <v-text-field
                  v-model="newCapitulation.players[index]"
                  outlined
                  dense
                  append-outer-icon="mdi-trash-can"
                  @click:append-outer="deletePlayer(player)"
                  style="height: 45px"
              ></v-text-field>
            </div>
            <p class="muted mb-0">*Vedno uporabljaj enako ime igralca zaradi pravilnega vodenja statistike!</p>
          </v-card>

          <v-card class="card-inner mt-5" outlined>
            <h3 class="mb-2">Podatki o kapitulaciji</h3>
            <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
            <h4 class="mb-5">
              Kapitulator:
            </h4>
            <v-radio-group v-model="newCapitulation.capitulatee">
              <v-radio
                  v-for="player in newGame.players"
                  :key="player.id"
                  :label="`${player}`"
                  :value="player"
                  @change="handleSelectedCapitulateeEvent(player)"
              ></v-radio>
            </v-radio-group>
            <h3 class="mb-2">Podatki o zmagovalcih</h3>
            <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
            <v-card v-for="(winner, index) in newCapitulation.winners" :key="winner.id" class="card-inner mt-5" outlined>
              <h3 class="mb-1">Zmagovalec {{index+1}}: {{winner.name}}</h3>
              <h4 class="mb-1">Likvidna sredstva:</h4>
              <v-text-field
                  v-model="winner.cash"
                  outlined
                  dense
                  style="height: 45px"
                  type="number"
                  label="Monopoly money"
                  class="my-4"
              ></v-text-field>
              <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
              <h4 class="mb-2">Zemljišča:</h4>
              <v-select
                  v-model="winner.properties"
                  :items="availableProperties"
                  outlined
                  chips
                  return-object
                  item-text="name"
                  label="Dodaj zemljišča zmagovalca"
                  multiple
              ></v-select>

              <div style="width: 100%; overflow-x: auto; white-space: nowrap;">
                <v-card
                    v-for="property in winner.properties" :key="property"
                    style="height: 350px; width: 250px!important; display: inline-block; border: solid 1px black"
                    class="ml-2"
                    outlined
                >
                  <div v-if="property.type === 'property'">
                    <div
                        :style="'background-color: ' + property.color"
                        class="text-center pb-1 pt-3"
                    >
                      <b style="font-size: 10px; color: white">LASTNIŠKA LISTINA</b>
                      <p style="font-size: 19px; font-weight: bold; color: white">{{ property.name }}</p>
                    </div>
                    <div style="display: flex; width: 100px; white-space: nowrap; padding: 10px">
                      <b style="margin-top: 4px; margin-right: 50px">Število hiš: </b>
                      <v-btn
                          x-small
                          fab
                          color="secondary"
                          :disabled="property.hotels !== 0"
                          @click="incrementHouse(property, -1)"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <b style="font-size: 25px; margin-top: 5px" class="mx-3">{{ property.houses }}</b>
                      <v-btn
                          x-small
                          fab
                          color="primary"
                          :disabled="property.hotels !== 0"
                          @click="incrementHouse(property, 1)"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                    <div style="display: flex; width: 100px; white-space: nowrap; padding: 10px">
                      <b style="margin-top: 4px; margin-right: 22px">Število hotelov: </b>
                      <v-btn
                          x-small
                          fab
                          color="secondary"
                          @click="incrementHotel(property, -1)"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <b style="font-size: 25px; margin-top: 5px" class="mx-3">{{ property.hotels }}</b>
                      <v-btn
                          x-small
                          fab
                          color="primary"
                          @click="incrementHotel(property, 1)"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                    <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
                    <div style="padding: 10px; margin-top: 20px">
                      <b>Cena vsake hiše: {{ property.housePrice }}</b>
                      <p style="font-weight: bold; margin-bottom: 0">Cena zemljišča: {{ property.price }}</p>
                    </div>
                    <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
                    <div style="padding: 10px; margin-top: 10px">
                      <b>Skupni vložek: {{ (property.housePrice * property.houses) + (property.housePrice * 5 * property.hotels) + property.price}}</b>
                      <div class="text-center">
                        <i style="font-size: 10px">© 1935, 2018 HASBRO</i>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="property.type === 'railway'">
                    <div
                        class="text-center pb-1 pt-3"
                    >
                      <v-img src="@/assets/train.png" style="width: 120px; margin: auto"></v-img>
                      <p style="font-size: 19px; font-weight: bold; margin-top: 20px">{{ property.name }}</p>
                    </div>
                    <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
                    <hr style="border-top: 1px solid var(--v-gray2-base); margin-top: 63px" class="mb-2">
                    <div style="padding: 10px; margin-top: 5px">
                      <p style="font-weight: bold; margin-bottom: 0">Cena železnice: {{ property.price }}</p>
                      <div class="text-center">
                        <i style="font-size: 10px">© 1935, 2018 HASBRO</i>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="property.type === 'utility'">
                    <div
                        class="text-center pb-1 pt-3"
                    >
                      <v-img v-if="property.name.includes('RAZSVETLJAVA')" src="@/assets/bulb.webp" style="width: 120px; margin: auto"></v-img>
                      <v-img v-else src="@/assets/water.webp" style="width: 120px; margin: auto"></v-img>
                      <p style="font-size: 19px; font-weight: bold; margin-top: 20px">{{ property.name }}</p>
                    </div>
                    <hr style="border-top: 1px solid var(--v-gray2-base)" class="mb-2">
                    <hr style="border-top: 1px solid var(--v-gray2-base); margin-top: 74px" class="mb-2">
                    <div style="padding: 10px; margin-top: 5px">
                      <p style="font-weight: bold; margin-bottom: 0">Cena javne storitve: {{ property.price }}</p>
                      <div class="text-center">
                        <i style="font-size: 10px">© 1935, 2018 HASBRO</i>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>

            </v-card>
          </v-card>

          <v-alert
              dense
              type="error"
              style="font-size: 14px; margin-top: 25px"
              v-if="errorMessage"
          >
            {{errorMessage}}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="secondary"
              outlined
              small
              @click="newCapitulationDialog = false;"
          >
            Prekliči
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="success"
              small
              @click="saveCapitulation()"
              :loading = loading
          >
            Shrani
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>

</template>

<script>
  import ApiConnector from "@/services/api-connector.service";
  import ApiRequest from "@/models/api-request";
  import moment from "moment";
  import {REQUEST_METHODS} from "@/models/request-methods";
  import YubikeyPrompt from "@/components/YubikeyPrompt.vue";

  export default {
    name: 'App',
    title: "Monopoly Leaderboard",
    components: {
      YubikeyPrompt
    },
    computed: {
      computedNewGameDate() {
        return this.formatDate(this.newGame.date)
      },
      computednewCapitulationDate() {
        return this.formatDate(this.newCapitulation.date)
      },
    },
    data() {
      return {
        leaderboard: { records: [], games: [] },

        newGameDialog: false,
        newGamePropertySelect: "",
        newGame: {
          date: "",
          properties: [],
          players: ["Jaka", "Jan", "Bine"]
        },

        newCapitulationDialog: false,
        newCapitulation: {
          date: "",
          capitulatee: "",
          players: ["Jaka", "Jan", "Bine"],
          winners: [],
        },

        statistics: {
          gamesPerMonth: [0,0,0,0,0,0,0,0,0,0,0,0],
          playerStatistics: [],
        },

        availableProperties: [],
        errorMessage: "",
        loading: false,
      }
    },
    mounted() {
      this.loadLeaderboard();
      this.loadProperties();
      this.newGame.date = moment().format('YYYY-MM-DD');
      this.newCapitulation.date = moment().format('YYYY-MM-DD');
    },
    methods: {

      loadLeaderboard() {
        ApiConnector.executeCall(ApiRequest.BuildWithURL("")).then(
            response => {
              this.leaderboard = response.data;
            },
            error => {
              console.log("Could not receive data");
              console.log(error.response.data);
            }
        )
        this.loadStatistics();
      },

      loadStatistics() {
        ApiConnector.executeCall(ApiRequest.BuildWithURL("statistics")).then(
            response => {
              this.statistics = response.data;
            },
            error => {
              console.log("Could not receive data");
              console.log(error.response.data);
            }
        )
      },


      loadProperties() {
        ApiConnector.executeCall(ApiRequest.BuildWithURL("properties")).then(
            response => {
              this.availableProperties = response.data;
            },
            error => {
              console.log("Could not receive data");
              console.log(error.response.data);
            }
        )
      },

      deletePlayer(player) {
        console.log("+ Trying to delete player: " + player)
        for (var i = 0; i<this.newGame.players.length; i++){
          if(
              player === this.newGame.players[i]
          ) {
            console.log("+--> Deleting player: " + player)
            this.newGame.players.splice(i,1);
            return;
          }
        }
      },

      incrementHouse(property, amount){
        property.houses = property.houses + amount
        if(property.houses >= 5){
          property.houses = 0;
          property.hotels = 1;
        }
        if(property.houses <= 0){
          property.houses = 0;
        }
      },

      incrementHotel(property, amount){
        property.hotels = property.hotels + amount
        if(property.hotels >= 1){
          property.hotels = 1;
          property.houses = 0;
        }
        if(property.hotels < 0){
          property.hotels = 0;
        }
      },

      formatDate(date){
        if (date === null || date === undefined) return ""
        let d = moment(date);
        return d.format("DD.MM.YYYY")
      },

      saveGame(){

        if(this.newGame.date === undefined || this.newGame.date === null || this.newGame.date === ""){
          this.errorMessage = "Datum igre manjka!"
          return;
        }

        if(this.newGame.players === undefined || this.newGame.players === null || this.newGame.players.length === 0){
          this.errorMessage = "Ni vnešenih igralcev!"
          return;
        }

        if(this.newGame.winner === undefined || this.newGame.winner === null || this.newGame.winner === ""){
          this.errorMessage = "Ni vnešenega zmagovalca!"
          return;
        }

        if(this.newGame.winnerCash === undefined || this.newGame.winnerCash === null || this.newGame.winnerCash === ""){
          this.errorMessage = "Ni vnešenih likvidnih sredstev zmagovalca!"
          return;
        }

        if(this.newGame.properties === undefined || this.newGame.properties === null || this.newGame.properties.length === 0){
          this.errorMessage = "Ni vnešenih zemljišč!"
          return;
        }

        this.$refs["yubi-prompt"]
            .open(
                "Verifikacija", "Prosim identificirajte se s ključem za zagotavljanje identitete (Yubikey)"
            ).then(
            resolve => {
              this.loading = true;
              ApiConnector.executeCall(ApiRequest.BuildWithURLAndMethodAndBody("game/" + resolve, REQUEST_METHODS.POST, this.newGame)).then(
                  () => {
                    this.newGame = {
                      date: "",
                      properties: [],
                      players: ["Jaka", "Jan", "Bine"]
                    }
                    this.loadLeaderboard();
                    this.newGameDialog = false;
                    this.loading = false;
                  },
                  error => {
                    console.log("Could not receive data");
                    console.log(error.response.data);
                    this.errorMessage = "Napaka pri shranjevanju. Preverite obrazec in identiteto!"
                    this.loading = false;
                  }
              )
            },
            reject => {
              console.log("Reject: " + reject)
            }
        )
      },

      saveCapitulation(){

        if(this.newCapitulation.date === undefined || this.newCapitulation.date === null || this.newCapitulation.date === ""){
          this.errorMessage = "Datum igre manjka!"
          return;
        }

        if(this.newCapitulation.players === undefined || this.newCapitulation.players === null || this.newCapitulation.players.length === 0){
          this.errorMessage = "Ni vnešenih igralcev!"
          return;
        }

        if(this.newCapitulation.capitulatee === undefined || this.newCapitulation.capitulatee === null || this.newCapitulation.capitulatee === ""){
          this.errorMessage = "Ni vnešenega kapitulatorja!"
          return;
        }

        this.$refs["yubi-prompt"]
            .open(
                "Verifikacija", "Prosim identificirajte se s ključem za zagotavljanje identitete (Yubikey)"
            ).then(
            resolve => {
              this.loading = true;
              ApiConnector.executeCall(ApiRequest.BuildWithURLAndMethodAndBody("capitulation/" + resolve, REQUEST_METHODS.POST, this.newCapitulation)).then(
                  () => {
                    this.newCapitulation = {
                      date: "",
                      capitulatee: "",
                      players: ["Jaka", "Jan", "Bine"],
                      winners: [],
                    },
                    this.loadLeaderboard();
                    this.newCapitulationDialog = false;
                    this.loading = false;
                  },
                  error => {
                    console.log("Could not receive data");
                    console.log(error.response.data);
                    this.errorMessage = "Napaka pri shranjevanju. Preverite obrazec in identiteto!"
                    this.loading = false;
                  }
              )
            },
            reject => {
              console.log("Reject: " + reject)
            }
        )
      },

      calculateGameCash(game){
        var total = game.winnerCash;
        for(var i = 0; i<game.properties.length; i++){
          total += game.properties[i].houses * game.properties[i].housePrice;
          total += game.properties[i].hotels * 5 * game.properties[i].housePrice;
          total += game.properties[i].price;
        }
        return total;
      },

      calculateCapitulationCash(capitulationWinner){
        var total = capitulationWinner.cash;
        for(var i = 0; i<capitulationWinner.properties.length; i++){
          total += capitulationWinner.properties[i].houses * capitulationWinner.properties[i].housePrice;
          total += capitulationWinner.properties[i].hotels * 5 * capitulationWinner.properties[i].housePrice;
          total += capitulationWinner.properties[i].price;
        }
        return total;
      },

      handleSelectedCapitulateeEvent(selectedName){
        console.log(selectedName)
        this.newCapitulation.winners = [];
        let winners = this.newCapitulation.players.filter(x => x !== selectedName);
        console.log(winners)
        for(let i = 0; i<winners.length; i++){
          this.newCapitulation.winners.push({
            name: winners[i],
            properties: [],
            cash: ""
          })
        }
      },


    }
  }
</script>

<style scoped>

  @import url('https://fonts.googleapis.com/css?family=Ubuntu');

  .app {
    /*background-image: url('~@/assets/bg1.jpeg');*/
    background-image: url('~@/assets/bg2.jpg');
    background-size: cover;
    height: 100vh;
    overflow-x: scroll;
    padding-top: 65px;

    /*background-color: #4158D0;
    background-image: linear-gradient(43deg, #FFCC70 0%, #C850C0 46%, #4158D0 100%);*/
  }

  .card {
    width: 1000px;
    margin: auto;
    padding: 20px;
    color: white;

    background: rgba(255, 255, 255, 0.19);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .card-inner {
    max-width: 1000px;
    margin: auto;
    padding: 20px;
  }

  .two-col-row {
    max-width: 1000px;
    margin: auto;
  }

  .muted {
    font-size: 13px;
    font-weight: 900;
    color: #1c1c1c;
  }

  .table {
    background-color: transparent !important;
    color: white;
  }

  .chip {
    display:flex;
    width: 100%;
    padding: 5px 15px;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .chip.zelen {
    background-color: rgba(17, 215, 17, 0.97);
  }

  .chip.rdec {
    background-color: rgba(248, 2, 2, 0.72);
  }

  .chip.siv {
    background-color: #8a8a8a;
  }

  .stat-card-container {

  }

  .stat-card {
    border: 1px solid white;
    border-radius: 20px;
    padding: 15px;
    margin: 15px;
    display: inline-block;
    min-width: 250px;
  }


    @media only screen and (max-width: 960px){
      .card {
        padding: 0px;
        width: 100%;
      }

      .card-inner {
        padding: 10px;
      }
    }

  @media only screen and (max-width: 500px){
    .card {
      padding: 15px;
    }
    .two-col-row {
      width: 100%;
    }
    .stat-card {
      display: block;
      margin: auto;
      margin-bottom: 15px;
    }
    .stat-card-container {
      padding: 25px;
    }
  }

</style>

<style lang="scss">
  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
</style>
