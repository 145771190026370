<template>
  <v-dialog
      v-model="dialog"
      :max-width="500"
      @keydown.esc="cancel"
      persistent
  >
    <v-card>
      <v-toolbar dark dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <p>{{ message }}</p>
        <v-text-field
            v-model="otp"
            outlined
            dense
            label="Identifikacijska koda"
            @keyup.enter="done"
            autofocus
        >
        </v-text-field>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">Zapri</v-btn>
        <v-btn depressed color="success" @click="done">Potrdi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: "",
    message: "",
    otp: ""
  }),
  methods: {
    open(title, message) {
      this.title = title;
      this.message = message;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    done() {
      this.resolve(this.otp);
      this.otp = "";
      this.dialog = false;
    },
    cancel() {
      this.reject(true);
      this.otp = "";
      this.dialog = false;
    }
  }
};
</script>
