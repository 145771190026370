import axios from 'axios';
import authHeader from "../headers/auth-header";
import EndpointService from "./endpoints.service"
import {REQUEST_METHODS} from "@/models/request-methods";


const API_URL = EndpointService.getApiV1Endpoint();

class ApiConnector {

    executeCall(apiRequest){

        try {
            if(apiRequest.requestMethod === REQUEST_METHODS.GET){
                return axios.get(API_URL + apiRequest.urlPath, { headers: authHeader() })
            }
            else if(apiRequest.requestMethod === REQUEST_METHODS.POST){
                return axios.post(API_URL + apiRequest.urlPath, apiRequest.body, { headers: authHeader() })
            }
            else if(apiRequest.requestMethod === REQUEST_METHODS.PUT){
                return axios.put(API_URL + apiRequest.urlPath, apiRequest.body, { headers: authHeader() })
            }
            else if(apiRequest.requestMethod === REQUEST_METHODS.DELETE){
                return axios.delete(API_URL + apiRequest.urlPath, { headers: authHeader() })
            }
            else {
                console.log("Unsupported method in API request: " + apiRequest.requestMethod)
            }
        }
        catch (exception) {
            console.error(exception);
        }
    }

}

export default new ApiConnector();
