import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                gray7: "#000000",
                gray6: "#1A1A1A",
                gray5: "#3A3939",
                gray4: "#959595",
                gray3: "#CECDCD",
                gray22: "#e3e3e3",
                gray2: "#EEEEEE",
                gray1: "#FAFAFA",
                gray0: "#FFFFFF",
                cyan: "#e3f7fc",
                sidebar: "#212A38",
                january: "#c9dcf3",
                february: "#e88a88",
                march: "#d2ace1",
                april: "#e8ead4",
                may: "#e9d7df",
                june: "#ebdacb",
                july: "#edc9b8",
                august: "#e39485",
                september: "#aba4a5",
                october: "#9c82b5",
                november: "#c4b083",
                december: "#8c8e81"
            },
            dark: {
                gray7: "#FFFFFF",
                gray6: "#F3F3F3",
                gray5: "#E7E7E7",
                gray4: "#717171",
                gray3: "#414141",
                gray22: "#2f2f2f",
                gray2: "#252525",
                gray1: "#191919",
                gray0: "#141414",
                cyan: "#4ca8c4",
                sidebar: "#111111",
                january: "#71839a",
                february: "#90302f",
                march: "#7a5488",
                april: "#8f927c",
                may: "#878088",
                june: "#928172",
                july: "#947160",
                august: "#813d2f",
                september: "#534b4c",
                october: "#442a5d",
                november: "#6c582a",
                december: "#333628"
            }
        }
    }
});
